portfolio-wrapper {
  justify-content: space-evenly;
}

.portfolio-items {
  display: grid;
  grid-template-columns: repeat(3, 350px);
  justify-content: space-between;
}

.portfolio-item {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  cursor: pointer;
  transition: opacity 400ms;
}

.portfolio-item:hover {
  opacity: 0.8;
  transition: opacity 400ms;
}

.portfolio-item-image {
  height: 200px;
}

@media screen and (max-width: 1280px) {
  .portfolio-items {
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    row-gap: 2rem;
  }
}

@media screen and (max-width: 992px) {
  .portfolio-items {
    grid-template-columns: 45% 45%;
    justify-content: space-between;
  }

  .portfolio-item-image {
    height: 150px;
  }
}

@media screen and (max-width: 650px) {
  .portfolio-items {
    grid-template-columns: 1fr;
    row-gap: 0;
  }
  
  .portfolio-item-image {
    height: 175px;
  }
  .portfolio-item {
    flex-basis: 80%;
    margin-bottom: 40px !important;
  }
}
