body {
  width: 100%;
  max-width: 1920px;
  height: 100vh;
  overflow-x: hidden;
  background-color: #dde1ec;
  margin: 0 auto;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  width: 100vw;
}

* {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}

/* colors */

.bg-dark-blue {
  background-color: #1e71bf;
}

.bg-md-blue {
  background-color: #1687d3;
}

.bg-light-blue {
  background-color: #04a7e7;
}

.bg-dull-white {
  background-color: #dde1ec;
}

.text-dark-blue {
  color: #1e71bf;
}

.text-md-blue {
  color: #1687d3;
}

.text-light-blue {
  color: #04a7e7;
}

.text-dull-white {
  color: #dde1ec;
}

.text-white {
  color: #ffffff;
}

.text-thin {
  font-weight: 300;
}

/* medium bold */
.text-sm-bold {
  font-weight: 500;
}

/*  Semi Bold */
.text-md-bold {
  font-weight: 600;
}

/* Bold */
.text-bold {
  font-weight: 700;
}

/* Bolder */
.text-x-bold {
  font-weight: 800;
}

.txt-orange {
  color: #f35c27 !important;
}

.sm-txt {
  font-size: 14px;
}

.md-txt {
  font-size: 1.25em;
}

.md-md-txt {
  font-size: 1.5em;
}

.copyright {
  margin-bottom: -1px;
}

.default-mini-title {
  font-weight: 600;
  font-size: 1.5em;
  font-family: "Lato", sans-serif;
}

.default-title {
  font-size: 3em;
  font-weight: 700;
  font-family: "Lato", sans-serif;
}

.lg-txt {
  font-size: 1.875em;
}

.xl-txt {
  font-size: 2.5em;
}
.default-container {
  width: 90%;
  margin: 3% auto 6%;
}

ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.default-y-spacing {
  margin-top: 80px;
  margin-bottom: 80px;
}

@media scree and (min-width: 1400px) {
  body {
    max-width: 1920px;
  }
}

@media screen and (max-width: 900px) {
  .default-mini-title {
    font-size: 1.25em;
  }
}

@media screen and (max-width: 650px) {
  .default-title {
    font-size: 2em !important;
  }
}

@media screen and (max-width: 380px) {
  .default-title {
    font-size: 1.65em !important;
  }
}
