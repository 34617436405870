.contact-wrapper {
  width: 100vw;
  height: 100%;
  position: relative;
}

.contact-form {
  width: 90%;
  margin: auto;
}

.contact-form-wrapper {
  width: 100%;
}

.form-group {
  padding-top: 20px 0 !important;
  margin-bottom: 30px;
  position: relative;
}

.form-label {
  color: #ffffff99;
  position: absolute;
  left: 20px;
  z-index: 0;
  display: none;
  animation: all 300ms;
}

.grecaptcha-badge {
  visibility: collapse !important;
}

.form-item {
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding-left: 20x;
  z-index: 1;
  border-bottom: 1px solid #ffffff99;
  font-size: 15px;
  color: #ffffff !important;
}

.form-item::placeholder {
  color: #ffffff99;
}

.form-item:focus {
  background-color: transparent;
  box-shadow: none;
  border-bottom-color: #ffffff;
}

.form-item:focus::placeholder {
  opacity: 0;
}

.description-input::placeholder {
  padding-top: 30px;
}

.form-item:focus ~ .form-label {
  top: -13px;
  color: #ffffff;
  display: block;
  animation: all 300ms;
}

.optional-field {
  color: #ffffff66;
  text-decoration: none;
}

.submit-btn {
  background-color: #ffffff;
  border: none;
  padding: 8px 24px;
  color: #1e71bf;
  text-align: center;
}

.submit-btn:disabled {
  background-color: #ffffff66;
  color: #1e71bf99;
  cursor: not-allowed;
}
.reach-out-wrapper {
  width: 70%;
  margin: auto;
  border: 1px solid #1e71bf99;
  box-shadow: 4px 6px 6px #00000044;
}
.reach-out-items-wrapper {
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: space-around;
}

.reach-out-items {
  flex-basis: 30%;
  text-align: center;
}

.icon-with-name-wrapper {
  display: flex;
  align-items: flex-start;
}

.icon-name-item {
  padding-left: 20px;
}

.icon-name-icon {
  width: 30px;
  height: 30px;
}

@media screen and (max-width: 900px) {
  .reach-out-items-wrapper {
    flex-direction: column;
  }
  .reach-out-items-wrapper {
    width: 100%;
    padding-left: 20%;
  }

  .reach-out-items:first-child {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 544px) {
  .reach-out-wrapper {
    width: 80%;
  }

  .contact-form {
    width: 100%;
  }
}

@media screen and (max-width: 414px) {
  .contact-form-wrapper {
    padding: 20px !important;
  }

  .reach-out-items-wrapper {
    padding-left: 30px !important;
  }
}

@media screen and (max-width: 380px) {
  .reach-out-items-wrapper {
    padding-left: 15px !important;
  }

  .reach-out-wrapper {
    width: 90%;
    margin: auto;
  }

  .reach-out-title {
    text-align: left !important;
    padding-left: 30px !important;
  }
}
