.main-header {
  width: 100%;
  max-height: 70px;
  margin: auto;
  z-index: 100 !important;
  animation: all 500ms;
}

.header-black {
  background-color: #042f58 !important;
  border-bottom: #ffffffaa;
  transition: all 600ms;
}

.header-items-wrapper {
  width: 90%;
  margin: auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.header-item {
  padding: 20px 0 10px;
  z-index: 100;
}

.logo-wrapper {
  width: 120px;
  height: 80px;
  position: absolute;
}

.logo {
  position: absolute;
  width: 150px;
  top: 10px;
}

.navbar-ul {
  list-style: none;
  display: flex;
  margin-right: 50px;
}

.navbar-ul > .nav-item {
  margin-left: 30px;
}

.nav-link {
  color: #ffffffaa !important;
}

.nav-item > .nav-link.active {
  color: #ffffff !important;
  font-weight: 500;
}

.menu-burger {
  display: none;
  font-size: 2em;
  color: #ffffff;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .main-header {
    height: 70px;
  }

  .header-items-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
  }
  .menu-burger {
    display: block;
    position: fixed;
    top: 20px;
    right: 70px;
  }

  .navbar-ul {
    flex-direction: column;
    padding: 0 !important;
    margin: 0;
  }
  .navbar-ul > .nav-item {
    background-color: #000000;
    margin: 0;
    padding: 5px 30px;
    border-bottom: 1px solid #686868;
  }

  .main-nav {
    position: fixed;
    left: -110%;
    width: 100vw;
    top: 70px;
    padding: 0;
  }

  .show-nav {
    animation: navShow 500ms 0s forwards;
  }

  .hide-nav {
    animation: navHide 500ms 0s forwards;
  }
}

@media screen and (max-width: 650px) {
  .logo {
    width: 100px;
    top: 0;
  }

  .menu-burger {
    font-size: 1.5em;
    top: 5px;
  }

  .main-header {
    height: 45px;
  }

  .main-nav {
    top: 40px;
  }
}

@media screen and (max-width: 544px) {
  .menu-burger {
    right: 40px;
    font-size: 1.2em;
    top: 7px;
  }
}

@keyframes navShow {
  100% {
    left: 0;
  }
}

@keyframes navHide {
  100% {
    left: -110%;
  }
}
