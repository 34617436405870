.services-wrapper {
  max-width: 1500px;
}
.service-items-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 auto;
}

.service-item {
  flex-basis: 45%;
  display: flex;
  flex-wrap: nowrap;
}

.service-image-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.service-image-icon {
  width: 80px;
  height: 80px;
}

@media screen and (max-width: 900px) {
  .service-item {
    flex-direction: column;
  }

  .service-image-icon-wrapper {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 650px) {
  .service-item {
    flex-basis: 80%;
  }
}
