#landing-wrapper {
  width: 100%;
  padding: 3%;
  max-width: 1920px;
  height: 100vh;
  max-height: 800px;
  position: relative;
  top: 0;
  left: 0;
}

.wrapper-items-container {
  display: flex;
  justify-content: space-between;
}

.landing-item {
  height: 100%;
  flex-basis: 50%;
  min-width: 50%;
  max-width: 50%;
  padding: 10% 5%;
}

.landing-text {
  padding: 10% 2%;
}

.landing-svg {
  width: 100%;
}

.email-bar-top {
  margin-top: 15%;
  width: 100%;
  animation: bounce 1s 2.5s forwards 2;
}

.email-input-top-wrapper {
  width: 80%;
  display: flex;
  flex-wrap: nowrap;
  height: 40px;
}

.email-input-top {
  background-color: transparent;
  border: 1px solid #ffffffdd;
  padding: 2% 5%;
  width: 70%;
}

.email-input-top::placeholder {
  color: #ffffffaa;
}

.link-to-form {
  text-decoration: none;
  background-color: #ffffffdd;
  width: 100px;
  text-align: center;
  padding: 2%;
}

.link-to-form:hover {
  text-decoration: none;
  color: rgb(30, 113, 191);
}

.landing-socials {
  position: absolute;
  bottom: 8%;
  left: 5%;
  width: 150px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.line-1,
.line-2 {
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  animation: typing 2s steps(20, end);
}

.line-2 {
  opacity: 0;
  animation: show 0s 1.5s forwards, typing 2s 1.5s steps(20, end);
}

@media screen and (max-width: 1300px) {
  #landing-wrapper {
    max-height: 620px;
  }
}

@media screen and (max-width: 1100px) {
  #landing-wrapper {
    max-height: 620px;
  }
  .landing-svg-wrapper {
    padding-top: 15%;
  }
  .default-title {
    font-size: 2.5em;
  }
}

@media screen and (max-width: 992px) {
  .landing-svg-wrapper {
    padding-top: 20%;
  }
}

@media screen and (max-width: 900px) {
  .landing-svg-wrapper {
    display: none;
  }

  .landing-item {
    min-width: 100%;
    max-width: 100%;
  }

  .landing-text {
    padding-left: 10%;
  }

  #landing-wrapper {
    height: 100%;
  }

  .email-bar-top {
    margin-top: 5%;
  }

  .email-input-top {
    width: 50%;
    color: #ffffff;
  }

  .landing-socials {
    bottom: 15px;
    left: 12%;
  }

  .landing-title {
    margin-top: 20px;
  }

  .line-2 {
    margin-top: 0 !important;
  }
}

.social-icons {
  cursor: pointer;
}

@media screen and (max-width: 650px) {
  .email-input-top {
    width: 70%;
  }
  .email-bar-top {
    margin-top: 70px;
    margin-bottom: 35px;
  }
  .email-input-top {
    padding-top: 1.5% !important;
    padding-bottom: 1.5% !important;
  }

  .link-to-form {
    padding-top: 2.2% !important;
    padding-bottom: 1% !important;
    font-weight: 600;
  }
}

@media screen and (max-width: 544px) {
  .link-to-form {
    padding-top: 3% !important;
  }

  .social-icons {
    height: 25px;
    width: 25px;
  }

  .landing-socials {
    width: 120px;
  }
}

@keyframes typing {
  from {
    width: 0%;
  }
}

@media screen and (max-width: 380px) {
  .email-input-top-wrapper {
    width: 95%;
  }

  .email-bar-top {
    margin-top: 40px;
  }

  .email-input-top {
    width: 80%;
  }

  .landing-text {
    padding-left: 10px !important;
  }

  .email-input-top-wrapper {
    height: 35px;
  }

  .link-to-form {
    padding-top: 2.2% !important;
  }

  .landing-socials {
    left: 5%;
  }
}

@keyframes show {
  to {
    opacity: 1;
  }
}
